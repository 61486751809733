
.full-screen {
  background: #4189f4;
  min-height: calc(100vh - 70px);
  display: flex;
  align-items: center;
  justify-content: center;
  .darksplit {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #1d73f4;
    clip-path: polygon(0 0, 100% 0, 100% 19%, 0 81%);
  }
  .clouds {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: url("~@/assets/header-clouds.svg");
  }
  .card-auth {
    margin: 100px 20px 20px 20px;
    width: 500px;
    height: auto;
    padding: 30px;
    border-radius: 10px;
    ::v-deep input.input,
    ::v-deep select {
      background-color: #f0f0f0;
    }
    #mascottes {
      background: url("~@/assets/mascottes.svg") no-repeat;
      height: 100px;
      width: 180px;
      position: absolute;
      right: 0;
      top: -85px;
    }
    #logo-text {
      font-size: 3rem;
      font-family: "Just Another Hand", cursive;
      color: white;
      position: absolute;
      right: left;
      top: -60px;
      left: 0;
    }
  }
}
